export enum AnimationSection {
  REVEAL = "reveal",
  BLUR = "blur",
  BLURFADE = "blur-fade",
  SCALEINERTIA = "scale-inertia",
}

export enum AnimationFrom {
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
  BOTTOM = "bottom",
}
