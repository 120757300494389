"use client";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  useZodForm,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { LoadingButton } from "@/features/form/SubmitButton";
// import { useMutation } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { AlertCircle, CheckCircle } from "lucide-react";
import { addLeadAction } from "./lead.action";
import type { LeadActionSchemaType } from "./lead.schema";
import { LeadActionSchema } from "./lead.schema";
import { Typography } from "@/components/ui/typography";
import Image from "@/atoms/Image";
import { cn } from "@/lib/utils";
import { Label } from "@/components/ui/label";
import { useState } from "react";
// import { CircleSvg } from "@/components/svg/CircleSvg";

export type LeadFormProps = {
  submitButtonLabel?: string;
  submitButtonVariant?:
    | "link"
    | "outline"
    | "default"
    | "destructive"
    | "secondary"
    | "ghost"
    | "invert"
    | null
    | undefined;
  arrowRevert?: boolean;
  arrowHidden?: boolean;
  className?: string;
  inputClassName?: string;
  children?: React.ReactNode;
};

export const LeadForm = ({
  submitButtonVariant,
  submitButtonLabel = "C'est parti",
  arrowRevert = false,
  arrowHidden = false,
  className = "",
  inputClassName = "",
  children,
}: LeadFormProps) => {
  const form = useZodForm({
    schema: LeadActionSchema,
  });
  const [submit, setSubmit] = useState<{
    isSuccess: boolean;
    isError: boolean;
    error: null | { message: string };
    isPending: boolean;
    mutate: (v: LeadActionSchemaType) => Promise<{}>;
  }>({
    isSuccess: false,
    isError: false,
    error: null,
    isPending: false,
    mutate: async ({
      email,
      phone,
      firstName,
      lastName,
    }: LeadActionSchemaType) => {
      setSubmit({ ...submit, isPending: true });
      const { serverError, data } = await addLeadAction({
        email,
        phone,
        firstName,
        lastName,
      });

      setSubmit({
        ...submit,
        isPending: false,
        isError: !data,
        error: !data ? new Error(serverError) : null,
        isSuccess: !!data,
      });

      if (data) {
        return data;
      } else {
        throw new Error(serverError);
      }
    },
  });

  return (
    <AnimatePresence mode="wait">
      {submit.isSuccess ? (
        <motion.div
          key="success"
          initial={{
            height: 0,
            opacity: 0,
          }}
          animate={{
            height: "auto",
            opacity: 1,
          }}
        >
          <Alert variant="success">
            <CheckCircle size={20} />

            {/* 
              successTitle = "A vous les conseils en ergonomie!",
              successMessage = `Pour recevoir nos conseils, il vous suffit de cliquer sur le lien de confirmation envoyé à : ${}.`,

            */}
            <AlertTitle>{`C'est reçu ${form.getValues("firstName")}`}</AlertTitle>
            <AlertDescription>
              <>
                {
                  "Nous prendrons contact avec vous dans 48h maximum sur votre numéro :"
                }
                {/* {
                  " Pour recevoir nos conseils, il vous suffit de cliquer sur le lien de confirmation envoyé à "
                } */}
                <br />
                <a href={`tel:${form.getValues("phone")}`}>
                  {form.getValues("phone")}
                </a>
                .
              </>
            </AlertDescription>
          </Alert>
        </motion.div>
      ) : (
        <motion.div
          key="form"
          className={cn("rounded-md p-5", className)}
          animate={{
            height: "auto",
            opacity: 1,
          }}
          exit={{
            height: 0,
            opacity: 0,
          }}
        >
          {/* IMPORANT 👇 */}
          <div
            className={cn("relative", {
              "h-[20px]": arrowHidden,
            })}
          >
            {arrowRevert ? (
              <Image
                color="black"
                className={cn(
                  // "absolute -top-20 left-28",
                  "-rotate-90 !shadow-none",
                )}
                alt="Arrow svg"
                width={40}
                height={60}
                src="/images/svg/curved-arrow.svg"
              />
            ) : (
              <Image
                color="black"
                className={cn(
                  `${arrowHidden ? "hidden" : ""}`,
                  // "absolute -right-24 -top-16",
                )}
                alt="Arrow svg"
                width={40}
                height={60}
                src="/images/svg/curved-arrow.svg"
              />
            )}

            <Typography variant={"h3"} className="text-center font-medium">
              {/* <CircleText>{"Boostez la santé "}</CircleText> */}

              {/* <span className="relative mr-3 inline-block">
                <CircleSvg className="fill-primary" />
              </span>
              {"et la productivité de vos employés"} */}
            </Typography>
            <Typography variant={"muted"} className="relative pt-4">
              {""}
              {/* <span className="mb-2 inline-block">
                {"Recevez dès aujourd'hui"}
              </span> */}
              {/* <span>{" directement dans votre boite email."}</span> */}
            </Typography>
            {/* <ul className="text-muted-foreground pb-4 indent-3 text-sm">
              <li>
                {"✔ Les bonnes pratiques pour "}
                <strong>{"optimiser la productivité"}</strong>
                {" de vos équipes"}
              </li>
              <li>
                {"✔ La checklist pour auditer l'érgonomie"}
                <strong>{" de votre entreprise"}</strong>
              </li>
            </ul> */}
          </div>
          {/* IMPORANT 🤞 */}

          {children && <div className="m-auto py-8">{children}</div>}

          <Form
            form={form}
            onSubmit={async (v) => submit.mutate(v)}
            className="flex flex-col gap-6"
            disabled={submit.isPending}
          >
            <div className="flex items-center  gap-4 max-md:flex-wrap">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem className="relative w-full">
                    <Label>{"Prénom"}</Label>

                    <FormControl>
                      <Input
                        className={cn("bg-foreground", inputClassName)}
                        placeholder="Jean"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="absolute -bottom-5" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem className="relative w-full">
                    <Label>{"Nom"}</Label>

                    <FormControl>
                      <Input
                        className={cn("bg-foreground", inputClassName)}
                        placeholder="DUPONT"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="absolute -bottom-5" />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex items-center  gap-4 max-md:flex-wrap">
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem className="relative w-full">
                    <Label>{"Téléphone"}</Label>
                    <FormControl>
                      <Input
                        type="tel"
                        autoFocus
                        className={cn("bg-foreground", inputClassName)}
                        placeholder="06 12 34 56 78"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="absolute -bottom-5" />
                  </FormItem>
                )}
              />
            </div>
            <div className="my-4">
              <LoadingButton
                about="leadform-submit"
                title={submitButtonLabel}
                variant={submitButtonVariant}
                className="animate-cta-primary w-full text-nowrap border-2 border-primary/90 font-bold hover:animate-none hover:bg-primary-foreground hover:text-primary/90"
                loading={submit.isPending}
              >
                {submitButtonLabel}
              </LoadingButton>
            </div>
            {submit.isError && (
              <Alert variant="destructive">
                <AlertCircle size={20} />
                <AlertTitle>{submit.error?.message}</AlertTitle>
                <AlertDescription>
                  {"Tentez avec un numéro différent ou contactez nous."}
                </AlertDescription>
              </Alert>
            )}
          </Form>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
