import { z } from "zod";

export const LeadActionSchema = z.object({
  email: z.string().email().optional(),
  phone: z.string().min(9),
  firstName: z.string().min(2),
  lastName: z.string().min(2),
});

export type LeadActionSchemaType = z.infer<typeof LeadActionSchema>;
