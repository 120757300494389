import { cn } from "@/lib/utils";
import type { AnimationFrom } from "@/types/animation";
import { AnimationSection } from "@/types/animation";
import dynamic from "next/dynamic";
import type { HTMLAttributes } from "react";
import React, { type ComponentPropsWithoutRef } from "react";

const ScaleInertiaContainer = dynamic(
  () => import("@/components/animated/ScaleInertiaContainer"),
  { ssr: false },
);
// const SectionReveal = dynamic(
//   () => import("@/components/custom/Section/SectionReveal"),
//   { ssr: false },
// );

const BlurContainer = dynamic(
  () => import("@/components/animated/BlurContainer"),
  { ssr: false },
);

const ViewTracker = dynamic(() => import("@/utils/ViewTracker"), {
  ssr: false,
});

export type SectionLayoutProps = {
  safePadding?: boolean;
  safeMargin?: boolean;
  safeHeight?: boolean;
  delay?: number;
  duration?: number;
  overlayColor?: "bg-foreground" | "bg-background" | null;
  once?: boolean;
  overlay?: React.ReactNode | null;
  bgLogo?: boolean | 10 | 20 | 50 | 70 | 80;
  /**
   * The section size.
   * sm = 896px
   * base = 1024px
   * lg = 1152px
   */
  size?: "sm" | "base" | "lg" | "full" | "full-screen" | "auto";
  /**
   * The variant of the section.
   * default = default background and foreground
   * card = card background and card foreground
   * primary = primary background and primary foreground
   * invert = foreground background and background foreground
   * image = background image with foreground text. The background image will be blured.
   */
  variant?: "default" | "card" | "primary" | "invert" | "image";
  type?: "flex-col" | "flex-row";
  animation?: AnimationSection;
  animateFrom?: AnimationFrom;
  containerClass?: HTMLAttributes<HTMLDivElement>["className"];
} & ComponentPropsWithoutRef<"section">;

export const SectionLayout = ({
  animation,
  size = "base",
  variant = "default",
  className,
  children,
  safeHeight = true,
  safePadding = false,
  safeMargin = false,
  animateFrom,
  delay,
  duration,
  overlayColor,
  once = true,
  type,
  containerClass,
  overlay = null,
  // bgLogo = false,
  ...props
}: SectionLayoutProps) => {
  // const getOpacityClassName = () => {
  //   switch (bgLogo) {
  //     case 20:
  //       return "opacity-20";
  //     case 50:
  //       return "opacity-50";
  //     case 80:
  //       return "opacity-80";
  //     case 70:
  //     default:
  //       return "opacity-70";
  //   }
  // };
  return (
    <section
      className={cn(
        "relative z-10",
        {
          "text-foreground": variant === "default",
          "bg-background": variant === "default",

          "text-card-foreground": variant === "card",
          "bg-card": variant === "card",

          "text-primary-foreground": variant === "primary",
          "bg-primary": variant === "primary",

          "text-background": variant === "invert",
          "bg-foreground": variant === "invert",

          "text-foreground backdrop-blur-sm backdrop-brightness-75":
            variant === "image",
          "w-full": size === "auto",
          "h-full min-h-safe-screen w-screen": size === "full" && safeHeight,
          "h-full min-h-screen w-screen": size === "full" && !safeHeight,
        },
        className,
      )}
      {...props}
    >
      {/* {bgLogo && (
        <div
          className={cn(
            "fixed left-0 top-0 size-full !bg-fixed -z-10",
            {
              "bg-foreground-logo": variant === "invert",
              "bg-background-logo": variant !== "invert",
            },
            getOpacityClassName(),
          )}
        />
      )} */}

      <AnimateSection
        id={props.id}
        {...{
          delay,
          variant,
          duration,
          animation,
          animateFrom,
          overlayColor,
          once,
        }}
      >
        <div
          // onViewportEnter={() => console.log("enter", props.id, props.key)}
          className={cn(
            "m-auto",
            {
              "max-w-4xl mx-auto overflow-hidden": size === "sm",
              "max-w-5xl mx-auto overflow-hidden": size === "base",
              "max-w-6xl mx-auto overflow-hidden": size === "lg",
              "max-w-7xl mx-auto overflow-hidden": size === "auto",
              [`flex flex-wrap ${type}`]: type?.includes("flex"),
              "py-header": safePadding,
              "my-header": safeMargin,
            },
            "p-4 sm:p-8 md:p-12",
            containerClass,
          )}
        >
          <ViewTracker
            id={props.id || (props.key as string)}
            name={props.about}
          />
          {children}
        </div>
      </AnimateSection>
      {overlay}
    </section>
  );
};

export type AnimatedSectionProps = {
  animation?: AnimationSection;
  animateFrom?: AnimationFrom;
  variant?: SectionLayoutProps["variant"];
} & ComponentPropsWithoutRef<"section">;

function AnimateSection({
  children,
  animation,
  ...props
}: AnimatedSectionProps) {
  switch (animation) {
    case AnimationSection.REVEAL:
      return (
        // <SectionReveal
        //   {...props}
        //   once
        //   overlayColor={
        //     props.variant === "invert" ? "bg-background" : "bg-foreground"
        //   }
        // >
        <div
          style={{ position: "relative", overflow: "hidden" }}
          className="flex size-full h-full flex-col items-center md:justify-center"
        >
          {children}
        </div>
        // </SectionReveal>
      );
    // case AnimationSection.BLURFADE:
    //   return <SectionBlurFade {...props}>{children}</SectionBlurFade>;
    case AnimationSection.SCALEINERTIA:
      return <ScaleInertiaContainer {...{ ...props, children }} />;
    case AnimationSection.BLUR:
      return <BlurContainer {...{ ...props, children }} />;
    default:
      return children;
  }
}

export * from "@/types/animation";
