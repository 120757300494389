
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"1be8d1957eba8f3ee6a5b399da5d0fa6576b50f1":"$$ACTION_0","d42ef4f49a4e07364db6a91245bed30789d0e140":"addLeadAction"} */ export var addLeadAction = createServerReference("d42ef4f49a4e07364db6a91245bed30789d0e140");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

